import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import Box from 'js/components/box/box';
import Col from 'js/components/grid/column';
import Collapse from 'js/components/collapse/collapse';
import FileButton from 'js/components/button/file-button';
import Label from 'js/components/label/label';
import Row from 'js/components/grid/row';
import Switch from 'js/components/switch/switch';
import styles from './input.module.scss';

let instanceId = 0;

const ImageInput = ({
    errorMessage,
    hasError,
    required,
    droparea,
    src,
    hint,
    info,
    label,
    buttonLabel,
    mimeTypes,
    collapsed,
    onCollapse,
    onExpand,
    ...fileButtonProps
}) => {
    const isCollapsible = collapsed !== undefined;
    const [thisInstanceId, setThisInstanceId] = useState('');
    useEffect(() => {
        instanceId += 1;
        setThisInstanceId(`input-${instanceId}`);
    }, []);

    return (
        <>
            <Row>
                <Col>
                    <Box margin={[isCollapsible ? 'small' : 0, 0, 'small']}>
                        <Label
                            forId={thisInstanceId}
                            hasError={hasError}
                            hint={!isCollapsible ? hint : null}
                            info={info}
                            label={label}
                            required={required}
                        />
                    </Box>
                </Col>
                {isCollapsible && (
                    <Col span="auto">
                        <Switch
                            labels={['No', 'Yes']}
                            isChecked={!collapsed}
                            onChange={collapsed ? onExpand : onCollapse}
                        />
                    </Col>
                )}
            </Row>
            <Collapse isOpen={!collapsed}>
                <Col>
                    {isCollapsible && hint ? (
                        <Box padding={['base', 'none', 'none']}>
                            <div className={styles['hint-container']}>
                                {hint}
                            </div>
                        </Box>
                    ) : null}
                    {src ? (
                        <Row>
                            <div className={styles['image-container']}>
                                <Box margin={['small', 'small', 'base']}>
                                    <img src={src} alt={label} />
                                </Box>
                            </div>
                        </Row>
                    ) : null}
                    <Row>
                        <div style={{ width: '100%' }}>
                            <Box margin={[0, 'small', 0]}>
                                <FileButton
                                    id={thisInstanceId}
                                    mimeTypes={mimeTypes}
                                    droparea={droparea}
                                    prefix={
                                        droparea ? (
                                            <FontAwesomeIcon
                                                icon={faCloudUploadAlt}
                                                size="3x"
                                            />
                                        ) : null
                                    }
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...fileButtonProps}
                                >
                                    {buttonLabel}
                                </FileButton>
                            </Box>
                        </div>
                    </Row>
                </Col>
                {errorMessage && hasError ? (
                    <div className={styles['error-message-container']}>
                        {errorMessage}
                    </div>
                ) : null}
            </Collapse>
        </>
    );
};

ImageInput.defaultProps = {
    errorMessage: undefined,
    hasError: false,
    required: false,
    droparea: false,
    src: undefined,
    hint: undefined,
    info: undefined,
    label: undefined,
    buttonLabel: 'Upload',
    mimeTypes: ['image/*'],
    collapsed: undefined,
    onCollapse: undefined,
    onExpand: undefined,
};

ImageInput.propTypes = {
    errorMessage: PropTypes.node,
    hasError: PropTypes.bool,
    required: PropTypes.bool,
    droparea: PropTypes.bool,
    src: PropTypes.string,
    hint: PropTypes.node,
    info: PropTypes.node,
    label: PropTypes.node,
    buttonLabel: PropTypes.node,
    mimeTypes: PropTypes.arrayOf(PropTypes.string),
    collapsed: PropTypes.bool,
    onCollapse: PropTypes.func,
    onExpand: PropTypes.func,
};

export default ImageInput;

import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import Box from 'js/components/box/box';
import Row from 'js/components/grid/row';
import Col from 'js/components/grid/column';
import Text from 'js/components/text/text';
import Alert from 'js/components/alert/alert';
import Button from 'js/components/button/button';
import Label from 'js/components/label/label';
import Panel from 'js/components/panel/panel';
import { knowledgeBaseYoutubeFileLink } from 'js/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import Lightbulb from '../images/lightbulb.svg';
import { CSV_MAX_SIZE_MB } from '../../constants';
import styles from './data-source-step.module.scss';
import templateFile from './yt_upload_template.csv';

const FileUploadContent = ({ filename, hasError }) =>
    filename && !hasError ? (
        <Box padding={['none', 'none', 'small']}>
            <Alert>
                <Row alignItems="center" justifyContent="center" gap="small">
                    <Col span="auto">
                        <FontAwesomeIcon
                            style={{ fontSize: '33px' }}
                            icon={faCheckCircle}
                        />
                    </Col>
                    <Col>
                        <p
                            className={styles['filename-container']}
                        >{`${filename} successfully uploaded.`}</p>
                    </Col>
                </Row>
            </Alert>
        </Box>
    ) : (
        <>
            <Panel theme="tertiary">
                <Box padding={['base']}>
                    <Row alignItems="center" justifyContent="center">
                        <Col span="auto">
                            <img src={Lightbulb} alt="lightbulb" />
                        </Col>
                        <Col>
                            <Text color={['purple', 'darker']}>
                                Download the template and add your formatted
                                data to it. Curious how to format your data?
                                Learn more on the{' '}
                                <a
                                    href={knowledgeBaseYoutubeFileLink}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Knowledge Base
                                </a>
                                .
                            </Text>
                        </Col>
                        <Col span="auto">
                            <Link
                                to={templateFile}
                                target="_blank"
                                download="4D_YT_UPLOAD_TEMPLATE.csv"
                            >
                                <Button theme="outline">
                                    Download Template
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                </Box>
            </Panel>

            <Box padding={['base', 'none', 'small']}>
                <Alert theme="empty">
                    <Text color={hasError && !filename ? ['red', 'dark'] : []}>
                        No file uploaded. File format CSV, max size{' '}
                        {CSV_MAX_SIZE_MB}MB.
                    </Text>
                </Alert>
            </Box>
        </>
    );

function FileSourceTab({ errors, filename, onFileUpload }) {
    const inputRef = useRef(null);
    const hasError = !!errors.filename || !!errors.file;

    return (
        <Label hasError={hasError} label="YouTube Data File To Optimize">
            <FileUploadContent filename={filename} hasError={hasError} />
            <Button onClick={() => inputRef?.current?.click()} theme="outline">
                <input
                    accept="text/csv, text/tsv"
                    ref={inputRef}
                    type="file"
                    onChange={(event) => {
                        onFileUpload(event.target.files[0]);
                        // allow reopening the same file on Chrome:
                        event.target.value = null; // eslint-disable-line no-param-reassign
                    }}
                    style={{ display: 'none' }}
                />
                Upload File
            </Button>
        </Label>
    );
}

export default FileSourceTab;

import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useState,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Buffer } from 'buffer'; // eslint-disable-line import/no-nodejs-modules
import moment from 'moment';
import entityTypes from 'js/enums/entity-types.enum';
import videoReportSubtypes from 'js/enums/video-report-subtypes.enum';
import {
    enumerate,
    api,
    deepCopy,
    getEventFilterForReportType,
    getEventTagSourceOrDefault,
    getPreset,
    setDocumentTitle,
    NotFoundError,
    csvQuoteSplit,
} from 'js/utils';
import Crumb from 'js/components/breadcrumbs/crumb';
import Layout from 'js/components/layout/layout';
import Navigation from 'js/components/navigation/navigation';
import TopBar from 'js/components/top-bar/top-bar';
import templateMethodMap from 'js/enums/templateMethodMap.enum';
import {
    CSV_MAX_SIZE_MB,
    EVENT_LOG_REPORT_MIN_DAYS_RANGE,
    MIN_DAYS_RANGE,
} from './constants';
import ReportTemplateDetail from './report-template-detail';

const requiredHeaders = {
    date: 'Date',
    url: 'Placement URL',
    impressions: 'Impressions',
    starts: 'Views',
    first_quartiles: 'Video played to 25%',
    midpoints: 'Video played to 50%',
    third_quartiles: 'Video played to 75%',
    completes: 'Video played to 100%',
};

const optionalHeaders = {
    clicks: 'Clicks',
};

const initialTemplate = {
    name: '',
    advertiser: null,
    context: null,
    source: '',
    campaigns: [],
    schedule: null,
    start_date: null,
    end_date: null,
    type: entityTypes.DISPLAY_REPORTS,
};

const templateLinkMap = {
    [entityTypes.DISPLAY_REPORTS]: 'display',
    [entityTypes.VIDEO_REPORTS]: 'video',
    [entityTypes.EVENT_LOG_REPORTS]: 'event-log',
};

const retrieveTemplateData = async (templateId) => {
    let templateData;
    const fields = [
        'id',
        'name',
        'advertiser',
        'context',
        'schedule',
        'start_date',
        'end_date',
        'subtype',
    ];

    try {
        templateData = await api().optimizeReportTemplates.retrieve(
            templateId,
            { field: fields },
        );
        templateData.type = entityTypes.DISPLAY_REPORTS;
    } catch (err) {
        try {
            if (!(err instanceof NotFoundError)) {
                throw err;
            }
            templateData = await api().videoReportTemplates.retrieve(
                templateId,
                { field: fields },
            );
            templateData.type = entityTypes.VIDEO_REPORTS;
        } catch (e) {
            if (!(e instanceof NotFoundError)) {
                throw e;
            }
            templateData = await api().eventLogReportTemplates.retrieve(
                templateId,
                { field: fields },
            );
            templateData.type = entityTypes.EVENT_LOG_REPORTS;
        }
    }

    return templateData;
};

export default function ReportTemplateDetailPage() {
    const { advertiserId: advertiserIdParam, templateId: templateIdParam } =
        useParams();
    const presetTemplate = getPreset();
    const [initialTemplateName, setInitialTemplateName] = useState('');
    const isNew = !templateIdParam;
    const history = useHistory();
    const [isLoading, setLoading] = useState(true);
    const [hasLoadingError, setHasLoadingError] = useState(false);
    const [template, setTemplate] = useState({
        ...deepCopy(initialTemplate),
        ...presetTemplate,
    });
    const [eventHierarchy, setEventHierarchy] = useState([]);
    const [isSaving, setSaving] = useState(false);
    const [savingErrors, setSavingErrors] = useState({});
    const [advertisers, setAdvertisers] = useState([]);
    const [contexts, setContexts] = useState([]);
    const [amending, setAmending] = useState({});
    const [minDaysRange, setMinDaysRange] = useState(MIN_DAYS_RANGE);

    const getBreadcrumbs = () => {
        const breadcrumbs = [
            <Crumb key="home" to="/" label="4D" />,
            <Crumb key="insights" to="/insights/" label="Insights" />,
        ];
        if (hasLoadingError) {
            breadcrumbs.push(<Crumb key="error" hasError />);
        } else if (isLoading) {
            breadcrumbs.push(<Crumb key="loading" isLoading />);
        } else if (isNew) {
            breadcrumbs.push(<Crumb key="insights" label="Create Report" />);
        } else {
            breadcrumbs.push(
                <Crumb
                    key="report"
                    label={initialTemplateName}
                    to={`/insights/${
                        templateLinkMap[template.type]
                    }/${templateIdParam}/`}
                    isCollapsible
                />,
            );
            breadcrumbs.push(<Crumb key="edit" label="Edit" />);
        }

        return breadcrumbs;
    };

    const loadWizardData = useCallback(async () => {
        setLoading(true);
        setHasLoadingError(false);

        try {
            const callbacks = [
                api().advertisers.list({ ordering: 'name' }),
                api().contexts.list({
                    ordering: '-updated',
                    is_public: false,
                    verbose: true,
                }),
                api().eventHierarchy.list({ verbose: true }),
            ];
            const [
                { results: advertisersData },
                { results: contextsData },
                { results: eventHierarchyData },
            ] = await Promise.all(callbacks);

            eventHierarchyData.sort(
                (a, b) =>
                    getEventTagSourceOrDefault(a.source).localeCompare(
                        getEventTagSourceOrDefault(b.source),
                    ) ||
                    a.campaign.localeCompare(b.campaign) ||
                    a.line_item.localeCompare(b.line_item),
            );

            const hasDisplayData = eventHierarchyData.some(
                (event) => event.is_video === false,
            );
            const firstVideoSubtype = Object.values(videoReportSubtypes).find(
                (subtypeValue) =>
                    eventHierarchyData.some(
                        getEventFilterForReportType(
                            entityTypes.VIDEO_REPORTS,
                            subtypeValue,
                        ),
                    ),
            );
            const type = hasDisplayData
                ? entityTypes.DISPLAY_REPORTS
                : entityTypes.VIDEO_REPORTS;
            const subtype = hasDisplayData
                ? null
                : firstVideoSubtype || videoReportSubtypes.YOUTUBE_GADS_FILE;

            const validContexts = contextsData.filter(
                (item) => item.advertiser, // TODO: fix broken contexts and drop this workaround (see AV-3418)
            );
            const candidateContexts = validContexts.filter(
                ({ is_video: isVideo }) =>
                    type === entityTypes.EVENT_LOG_REPORTS ||
                    isVideo === (type === entityTypes.VIDEO_REPORTS),
            );
            const firstContext =
                candidateContexts.find(
                    ({ id }) => id === presetTemplate.context,
                ) ||
                candidateContexts.find(
                    ({ advertiser }) =>
                        advertiser === presetTemplate.advertiser,
                ) ||
                candidateContexts[0];
            const { source: firstSourceId, campaign: firstCampaignId } =
                eventHierarchyData.find(
                    getEventFilterForReportType(type, subtype),
                ) || {};

            setAdvertisers(advertisersData);
            setContexts(validContexts);
            setEventHierarchy(eventHierarchyData);
            setTemplate((prevState) => ({
                ...prevState,
                advertiser: firstContext?.advertiser,
                context: firstContext?.id,
                source:
                    subtype === videoReportSubtypes.YOUTUBE_GADS_FILE
                        ? ''
                        : firstSourceId,
                campaigns:
                    subtype === videoReportSubtypes.YOUTUBE_GADS_FILE
                        ? []
                        : [{ campaign_id: firstCampaignId, line_items: [] }],
                type,
                subtype,
            }));
        } catch (err) {
            setHasLoadingError(true);
        } finally {
            setLoading(false);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const loadTemplateData = useCallback(async () => {
        setLoading(true);
        setHasLoadingError(false);

        try {
            const unblock = history.block(
                ({ pathname }) => pathname !== '/errors/404/',
            );
            const templateData = await retrieveTemplateData(templateIdParam);
            unblock();
            if (templateData.advertiser !== advertiserIdParam) {
                throw new Error('invalid URL');
            }
            setTemplate(templateData);
            setInitialTemplateName(templateData.name);
        } catch (err) {
            setHasLoadingError(true);
        } finally {
            setLoading(false);
        }
    }, [advertiserIdParam, templateIdParam, history]);

    const isRangeValid = (startDate, endDate) =>
        moment(endDate).diff(moment(startDate), 'days') + 1 >= minDaysRange;

    const validateDataFile = (data) => {
        const lines = data.split(/\r?\n/);
        const headersLine = lines[0];
        const headersLineSplit = headersLine?.split(/[,\t]/);
        const missingHeaders = Object.values(requiredHeaders).filter(
            (header) => !headersLineSplit.includes(header),
        );

        if (missingHeaders.length) {
            const missingHeadersList = enumerate(missingHeaders, 3).join('');
            throw Error(
                `The file doesn’t include ${missingHeadersList}${
                    missingHeaders.length > 3
                        ? ` and ${missingHeaders.length - 3} more`
                        : ''
                }. Please update the file with ${
                    missingHeaders.length > 1 ? 'these headers' : 'this header'
                } and try again.`,
            );
        }

        const dateIndex = headersLineSplit.indexOf(requiredHeaders.date);

        lines.slice(1).forEach((l) => {
            const cells = l.split(',');
            const isEmptyLine = cells.every((c) => c === '');

            if (!isEmptyLine) {
                if (
                    cells.length !== headersLineSplit.length ||
                    cells.some((c) => c === '')
                ) {
                    throw Error(
                        'The file contains rows with incomplete data. Please correct the errors and try again.',
                    );
                }

                if (
                    !/^(20\d\d-\d\d-\d\d)|(\d\d\/\d\d\/20\d\d)$/.test(
                        cells[dateIndex],
                    )
                ) {
                    throw Error(
                        'The file contains dates with incorrect formatting. Accepted formats include YYYY-MM-DD or DD/MM/YYYY. Please correct the errors and try again.',
                    );
                }
            }
        });
    };

    const validateTemplate = ({
        fields = ['name', 'context', 'schedule'],
        throwErrors = false,
    } = {}) => {
        const amendingUpdates = {};
        const errors = { ...savingErrors };

        fields.forEach((field) => {
            amendingUpdates[field] = true;
            delete errors[field];

            if (field === 'name' && !template.name) {
                errors.name = 'A report name is required.';
            }

            if (field === 'context' && !template.context) {
                errors.context = 'A context to optimize is required.';
            }

            if (
                field === 'schedule' &&
                template.subtype !== videoReportSubtypes.YOUTUBE_GADS_FILE &&
                template.start_date &&
                template.end_date &&
                !isRangeValid(template.start_date, template.end_date)
            ) {
                errors.schedule = `Your custom dates must span at least ${minDaysRange} days.`;
            }

            if (field === 'filename' && !template.filename) {
                errors.filename = 'A data file is required.';
            }

            if (field === 'file') {
                try {
                    if (!template.file) {
                        throw Error('Unable to open the file.');
                    }
                    validateDataFile(template.file);
                } catch (error) {
                    errors.file = `The upload of the file '${template.filename}' was unsuccessful. ${error.message}`;
                }
            }
        });

        setAmending({ ...amending, ...amendingUpdates });
        setSavingErrors(errors);
        if (throwErrors && Object.keys(errors).length) {
            throw errors;
        }
    };

    // eslint-disable-next-line sonarjs/cognitive-complexity
    const processDataFile = (data) => {
        const lines = data.split(/\r?\n/);
        const headersLine = lines[0];
        const separator = headersLine.includes('\t') ? '\t' : ',';
        const headers = csvQuoteSplit(headersLine, separator);
        const outputHeaders = { ...requiredHeaders, ...optionalHeaders };

        const outputRows = [];
        let minDate;
        let maxDate;
        lines.forEach((line) => {
            const inputRow = csvQuoteSplit(line, separator);
            let impressions = 0;
            let url = '';
            const outputRow = Object.values(outputHeaders).map((header) => {
                const index = headers.indexOf(header);
                let value = index >= 0 ? inputRow[index] : 0;
                if (/^\d+(,\d\d\d)+$/.test(value)) {
                    value = value.replace(/,/g, '');
                }
                if (
                    header === requiredHeaders.date &&
                    /^(20\d\d-\d\d-\d\d)|(\d\d\/\d\d\/20\d\d)$/.test(value)
                ) {
                    value = value.replace(
                        /(\d\d)\/(\d\d)\/(20\d\d)/,
                        '$3-$2-$1',
                    );
                    if (!minDate || value < minDate) minDate = value;
                    if (!maxDate || value > maxDate) maxDate = value;
                } else if (header === requiredHeaders.impressions) {
                    impressions = parseInt(value, 10) || 0;
                } else if (header === requiredHeaders.url) {
                    value = value?.replace(
                        /^https?:\/\/(www\.)?youtube.com\/video\/([^/]+).*$/,
                        'https://www.youtube.com/watch?v=$2',
                    );
                    url = value;
                } else if (/^ *-- *$/.test(value)) {
                    value = 0;
                } else if (/^\d+\.\d\d%$/.test(value)) {
                    value = Math.round((impressions * parseFloat(value)) / 100);
                }
                return value;
            });
            if (url) {
                outputRows.push(outputRow);
            }
        });

        let output = Object.keys(outputHeaders).join('\t');
        output += '\n';
        output += outputRows.map((row) => row.join('\t')).join('\n');

        return [output, minDate, maxDate];
    };

    const saveTemplate = async () => {
        setSavingErrors({});
        setSaving(true);

        try {
            validateTemplate({ throwErrors: true });
            if (isNew) {
                if (
                    template.type === entityTypes.VIDEO_REPORTS &&
                    template.subtype === videoReportSubtypes.YOUTUBE_GADS_FILE
                ) {
                    const [data, minDate, maxDate] = processDataFile(
                        template.file,
                    );
                    await api().videoReportTemplates.create({
                        ...template,
                        schedule: null,
                        start_date: minDate,
                        end_date: maxDate,
                        source: '',
                        file: Buffer.from(data).toString('base64'),
                    });
                } else {
                    await api()[templateMethodMap[template.type]].create({
                        ...template,
                        file: null,
                    });
                }
            } else {
                await api()[templateMethodMap[template.type]].update(
                    template.id,
                    {
                        name: template.name,
                        schedule: template.schedule,
                        start_date: template.start_date,
                        end_date: template.end_date,
                    },
                );
            }
            await history.push(
                isNew
                    ? `/insights/${template.advertiser}/`
                    : `/insights/${templateLinkMap[template.type]}/${
                          template.id
                      }/`,
            );
        } catch (errors) {
            setSavingErrors(errors);
            setSaving(false);
        }
    };

    const uploadDataFile = (file) => {
        const isFileSizeValid = file.size <= CSV_MAX_SIZE_MB * 1024 * 1024;
        const isFileTypeValid =
            file.type === 'text/csv' ||
            file.type === 'text/tsv' ||
            file.type === 'text/tab-separated-values' ||
            (file.type === 'application/vnd.ms-excel' &&
                (file.name.endsWith('.csv') || file.name.endsWith('.tsv')));

        setTemplate({ ...template, filename: file.name });
        if (!isFileSizeValid) {
            setSavingErrors((prev) => ({
                ...prev,
                file: `
                    The upload of the file '${file.name}' was unsuccessful.
                    The file size exceeds our maximum of ${CSV_MAX_SIZE_MB}MB. Please reduce the file size and try again.`,
            }));
        } else if (!isFileTypeValid) {
            setSavingErrors((prev) => ({
                ...prev,
                file: `
                    The upload of the file '${file.name}' was unsuccessful.
                    Files must be uploaded in CSV format. Please adjust the file type and try again.`,
            }));
        } else {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onloadend = (readerEvent) => {
                const data = readerEvent.target.result;
                setTemplate((prev) => ({ ...prev, file: data }));
            };
        }
    };

    useLayoutEffect(() => {
        setSavingErrors({});

        if (isNew) {
            setAmending(false);
            setTemplate({ ...deepCopy(initialTemplate), ...presetTemplate });
            loadWizardData();
        } else {
            loadTemplateData();
            setAmending({ name: true });
        }

        return () => {
            api().abortAll();
        };
    }, [isNew, loadWizardData, loadTemplateData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isNew) {
            setDocumentTitle(['Create Insights Report', 'Insights']);
        } else if (initialTemplateName) {
            setDocumentTitle(['Edit', initialTemplateName, 'Insights']);
        } else {
            setDocumentTitle(['Insights']);
        }
    }, [isNew, initialTemplateName]);

    useEffect(() => {
        setSavingErrors((prevState) => {
            const newState = { ...prevState };
            delete newState.name;
            return newState;
        });

        if (amending.name) {
            validateTemplate({ fields: ['name'] });
        }
    }, [template.name]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSavingErrors((prevState) => {
            const newState = { ...prevState };
            delete newState.context;
            return newState;
        });
    }, [template.context]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSavingErrors((prevState) => {
            const newState = { ...prevState };
            delete newState.filename;
            return newState;
        });

        if (amending.filename) {
            validateTemplate({ fields: ['filename'] });
        }
    }, [template.filename]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSavingErrors((prevState) => {
            const newState = { ...prevState };
            delete newState.file;
            return newState;
        });

        if (template.filename) {
            validateTemplate({ fields: ['file'] });
        }
    }, [template.file]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSavingErrors((prevState) => {
            const newState = { ...prevState };
            delete newState.schedule;
            return newState;
        });

        if (amending.schedule) {
            validateTemplate({ fields: ['schedule'] });
        }
    }, [template.schedule, template.start_date, template.end_date]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isNew) return;

        setSavingErrors({});
        if (template.type === entityTypes.EVENT_LOG_REPORTS) {
            setMinDaysRange(EVENT_LOG_REPORT_MIN_DAYS_RANGE);
            setTemplate((prev) => ({
                ...prev,
                schedule: null,
                start_date: null,
                end_date: null,
            }));
        } else {
            setMinDaysRange(MIN_DAYS_RANGE);
        }
    }, [template.type]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSavingErrors({});
        setTemplate((prev) => ({ ...prev, filename: null, file: null }));
    }, [template.subtype]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        validateTemplate({ fields: ['schedule'] });
    }, [minDaysRange]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Layout
            header={<TopBar breadcrumbs={getBreadcrumbs()} />}
            sidebar={<Navigation />}
        >
            <ReportTemplateDetail
                template={template}
                eventHierarchy={eventHierarchy}
                advertisers={advertisers}
                contexts={contexts}
                isLoading={isLoading}
                hasLoadingError={hasLoadingError}
                isSaving={isSaving}
                savingErrors={savingErrors}
                onChange={setTemplate}
                onValidate={(fields) =>
                    validateTemplate({ fields, throwErrors: true })
                }
                onSave={saveTemplate}
                onReload={isNew ? loadWizardData : loadTemplateData}
                onFileUpload={uploadDataFile}
            />
        </Layout>
    );
}

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { validMimeType } from 'js/utils';
import Button from 'js/components/button/button';
import DropArea from 'js/components/drop-area/drop-area';

const cropImage = (img, type = 'image/png', rect = []) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0, img.width, img.height);
    const { data: imgData } = ctx.getImageData(0, 0, img.width, img.height);

    const isTransparent = ({ a }) => a === 0;
    const isWhitish = ({ r, g, b, a }) =>
        a === 255 && r >= 250 && g >= 250 && b >= 250;

    let [x0, y0, x1, y1] = rect;
    const autocrop = rect.length !== 4;
    if (autocrop) {
        [x0, y0] = [Infinity, Infinity];
        [x1, y1] = [0, 0];
        for (let y = 0, i = 0; y < canvas.height; y += 1) {
            for (let x = 0; x < canvas.width; x += 1, i += 4) {
                const [r, g, b, a] = imgData.slice(i, i + 4);
                const pixel = { r, g, b, a };
                if (!isTransparent(pixel) && !isWhitish(pixel)) {
                    if (x < x0) x0 = x;
                    if (y < y0) y0 = y;
                    if (x > x1) x1 = x;
                    if (y > y1) y1 = y;
                }
            }
        }
    }

    const croppedWidth = x1 - x0 + 1;
    const croppedHeight = y1 - y0 + 1;
    const croppedData = ctx.getImageData(x0, y0, croppedWidth, croppedHeight);
    canvas.width = croppedWidth;
    canvas.height = croppedHeight;
    ctx.putImageData(croppedData, 0, 0);
    const croppedImg = document.createElement('img');
    croppedImg.src = canvas.toDataURL(type);

    return croppedImg;
};

function FileButton({
    onClick,
    onLoadStart,
    onLoadEnd,
    onError,
    droparea,
    autocrop,
    mimeTypes,
    minSize,
    maxSize,
    minImageHeight,
    maxImageHeight,
    minImageWidth,
    maxImageWidth,
    children,
    ...buttonProps
}) {
    const inputRef = useRef(null);
    const [isDropActive, setIsDropActive] = useState(false);

    const handleChange = (file) => {
        if (!validMimeType(file.type, mimeTypes)) {
            onError(`Unsupported file type`);
            return;
        }
        if (maxSize && file.size > maxSize) {
            const maxSizeKb = Math.floor(maxSize / 1024);
            onError(
                `The file is too big, must be less than ${maxSizeKb} kilobytes`,
            );
            return;
        }
        if (minSize && file.size < minSize) {
            onError(
                `The file is too small, must be more than ${minSize} bytes`,
            );
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadstart = () => {
            if (onLoadStart) onLoadStart();
        };
        reader.onloadend = async (ev) => {
            let data = ev.target.result;
            try {
                if (data === null) {
                    throw Error('Unable to open the file');
                }
                if (file.type.startsWith('image/')) {
                    const image = new Image();
                    image.src = data;
                    try {
                        await image.decode();
                    } catch (error) {
                        throw Error('Unable to read the image');
                    }

                    let croppedImage = image;
                    if (autocrop) {
                        try {
                            croppedImage = cropImage(image, file.type);
                            await croppedImage.decode();
                            data = croppedImage.src;
                        } catch (error) {
                            throw Error('Unable to crop the image');
                        }
                    }

                    if (minImageHeight) {
                        if (image.height < minImageHeight) {
                            throw Error(
                                `The image is smaller than the minimum height of ${minImageHeight}px. ` +
                                    'Please try a larger image.',
                            );
                        }
                        if (croppedImage.height < minImageHeight) {
                            throw Error(
                                `The image is smaller than the minimum height of ${minImageHeight}px after empty space has been trimmed from the uploaded image. ` +
                                    'Please try a larger image, or an image with less empty space around the edges.',
                            );
                        }
                    }
                    if (
                        maxImageHeight &&
                        croppedImage.height > maxImageHeight
                    ) {
                        throw Error(
                            `The image height is larger than the maximum height of ${maxImageHeight}px. ` +
                                'Please try a smaller image.',
                        );
                    }
                    if (minImageWidth) {
                        if (image.width < minImageWidth) {
                            throw Error(
                                `The image is smaller than the minimum width of ${minImageWidth}px. ` +
                                    'Please try a larger image.',
                            );
                        }
                        if (croppedImage.width < minImageWidth) {
                            throw Error(
                                `The image is smaller than the minimum width of ${minImageWidth}px after empty space has been trimmed from the uploaded image. ` +
                                    'Please try a larger image, or an image with less empty space around the edges.',
                            );
                        }
                    }
                    if (maxImageWidth && croppedImage.width > maxImageWidth) {
                        throw Error(
                            `The image width is larger than the maximum width of ${maxImageWidth}px. ` +
                                'Please try a smaller image.',
                        );
                    }
                }
            } catch (error) {
                onError(error.message);
                return;
            }
            if (onLoadEnd) onLoadEnd(data);
        };
    };

    const handleClick = (evt) => {
        if (onClick) onClick(evt);
        inputRef.current.click();
    };

    return (
        <>
            <input
                ref={inputRef}
                type="file"
                accept={mimeTypes.join(',')}
                onChange={(ev) => {
                    handleChange(ev.target.files[0]);
                    // eslint-disable-next-line no-param-reassign
                    ev.target.value = null;
                }}
                style={{ display: 'none' }}
            />

            {droparea ? (
                <DropArea
                    mimeTypes={mimeTypes}
                    onEnter={() => setIsDropActive(true)}
                    onLeave={() => setIsDropActive(false)}
                    onDrop={(file) => {
                        setIsDropActive(false);
                        handleChange(file);
                    }}
                >
                    <Button
                        className="droparea"
                        onClick={handleClick}
                        active={isDropActive}
                        {...buttonProps} // eslint-disable-line react/jsx-props-no-spreading
                    >
                        {children}
                    </Button>
                </DropArea>
            ) : (
                <Button
                    onClick={handleClick}
                    {...buttonProps} // eslint-disable-line react/jsx-props-no-spreading
                >
                    {children}
                </Button>
            )}
        </>
    );
}

FileButton.defaultProps = {
    onClick: undefined,
    onLoadStart: undefined,
    onLoadEnd: undefined,
    onError: undefined,
    droparea: false,
    autocrop: false,
    mimeTypes: [],
    minSize: 0,
    maxSize: 1048576,
    minImageHeight: 1,
    maxImageHeight: 2048,
    minImageWidth: 1,
    maxImageWidth: 2048,
};

FileButton.propTypes = {
    onClick: PropTypes.func,
    onLoadStart: PropTypes.func,
    onLoadEnd: PropTypes.func,
    onError: PropTypes.func,
    droparea: PropTypes.bool,
    autocrop: PropTypes.bool,
    mimeTypes: PropTypes.array,
    minSize: PropTypes.number,
    maxSize: PropTypes.number,
    minImageHeight: PropTypes.number,
    maxImageHeight: PropTypes.number,
    minImageWidth: PropTypes.number,
    maxImageWidth: PropTypes.number,
    children: PropTypes.node.isRequired,
};

export default FileButton;

import { pdf, PDFViewer } from '@react-pdf/renderer';
import * as FileSaver from 'file-saver';
import html2canvas from 'html2canvas';
import { cleanFileName } from 'js/utils';
import videoReportSubtypes from 'js/enums/video-report-subtypes.enum';
import logo from 'media/images/logos/logo.svg';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Checkbox from 'js/components/checkbox/checkbox';
import Container from 'js/components/container/container';
import Drawer from 'js/components/drawer/drawer';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import ImageInput from 'js/components/input/image-input';
import Input from 'js/components/input/input';
import Label from 'js/components/label/label';
import LoadingLayer from 'js/components/loading-layer/loading-layer';
import Panel from 'js/components/panel/panel';
import Switch from 'js/components/switch/switch';
import Text from 'js/components/text/text';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import DisplayReport from '../pdf-report/display-report';
import DisplayCharts from '../pdf-report/display-charts';
import VideoReport from '../pdf-report/video-report';
import VideoCharts from '../pdf-report/video-charts';
import { getBase64ImageSource } from '../pdf-report/utils';
import PdfPageSelector from './customization-bar/pdf-page-selector';
import { displayPdfPages, videoPdfPages } from './enums/pdf-pages.enum';

const CustomImageInput = ({
    brandLogo,
    collapsed,
    errors,
    logoError,
    onLogoChange,
    onCollapse,
    onExpand,
    onLogoError,
}) => (
    <Box
        margin={['large', 0]}
        padding="base"
        borderRadius="round"
        background={['gray', 'background']}
    >
        <ImageInput
            label="Customize With Brand Logo"
            info={
                <>
                    <p>
                        Customize the look and feel of the report to match your
                        brand by uploading a custom logo.
                    </p>
                    <p>
                        This logo will appear at various points throughout the
                        report.
                    </p>
                </>
            }
            hint={
                <p>
                    The image used for the logo must be at least 200px tall and
                    be a png or jpeg file.
                </p>
            }
            buttonLabel={
                <em>
                    Drag and drop (or click) here to{' '}
                    {logo ? 'replace the' : 'upload a'} logo.
                </em>
            }
            autocrop
            collapsed={collapsed}
            droparea
            hasError={!!logoError || !!errors.logo}
            errorMessage={<p>{logoError || errors.logo}</p>}
            mimeTypes={['image/jpeg', 'image/png']}
            minImageHeight={200}
            src={brandLogo}
            onCollapse={onCollapse}
            onExpand={onExpand}
            onError={onLogoError}
            onLoadStart={() => onLogoError('')}
            onLoadEnd={(data) => onLogoChange(data)}
        />
    </Box>
);

const PdfDownloadDrawer = ({ report, template, isVideo, onClose }) => {
    const reportPages = isVideo ? videoPdfPages : displayPdfPages;
    let allPages = Object.keys(reportPages).map((p) => ({
        id: p,
        label: reportPages[p],
    }));
    if (!isVideo && report.keywords.length === 0) {
        allPages = allPages.filter((p) => p.label !== displayPdfPages.KEYWORDS);
    }
    if (report.topics.length === 0) {
        allPages = allPages.filter(
            (p) =>
                p.label !== displayPdfPages.TOPICS &&
                p.label !== videoPdfPages.TOPICS,
        );
    }
    if (isVideo && report.logos.length === 0) {
        allPages = allPages.filter((p) => p.label !== videoPdfPages.LOGOS);
    }

    const [isGenerating, setIsGenerating] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isCustomerLogoCollapsed, setIsCustomerLogoCollapsed] =
        useState(true);
    const [reportName, setReportName] = useState(template.name);
    const [advertiserName, setAdvertiserName] = useState(
        template.advertiser_name || '',
    );
    const [campaignName, setCampaignName] = useState(
        template.context_group_name || '',
    );

    const [orientation, setOrientation] = useState('landscape');
    const [pages, setPages] = useState(allPages);

    const [amending, setAmending] = useState(false);
    const [errors, setErrors] = useState({});

    const [has4DLogo, setHas4DLogo] = useState(false);
    const [logo4D, setLogo4D] = useState(false);
    const [brandLogo, setBrandLogo] = useState(null);
    const [topicGroupLogos, setTopicGroupLogos] = useState([]);
    const [charts, setCharts] = useState({});

    const chartRefs = useRef({});

    const Report = isVideo ? VideoReport : DisplayReport;
    const Charts = isVideo ? VideoCharts : DisplayCharts;

    const isDV360Report =
        template.subtype === videoReportSubtypes.YOUTUBE_DV360;

    const fileNameParts = [
        '4D report',
        reportName,
        advertiserName,
        campaignName,
    ].filter((part) => part);
    const fileName = `${fileNameParts.join(' - ')}.pdf`;

    const reportWithLogos = {
        ...report,
        topic_groups: report.topic_groups.map((t, i) => ({
            ...t,
            logobase64: topicGroupLogos[i],
        })),
    };

    const validate = useCallback(
        ({ throwErrors = false } = {}) => {
            const errs = {
                name:
                    errors.name ||
                    (!reportName && 'A Report Name is required.'),
                logo:
                    !isCustomerLogoCollapsed &&
                    (errors.logo ||
                        (!brandLogo &&
                            'A logo is required to customize the report with a brand logo.')),
            };
            if (throwErrors && Object.values(errs).some((err) => !!err)) {
                throw errs;
            }
            return errs;
        },
        [
            brandLogo,
            errors.logo,
            errors.name,
            reportName,
            isCustomerLogoCollapsed,
        ],
    );

    const generateCharts = async () => {
        const canvases = await Promise.all(
            Object.values(chartRefs.current).map((ref) =>
                html2canvas(ref.current),
            ),
        );

        setCharts(
            Object.fromEntries(
                Object.keys(chartRefs.current).map((key, index) => [
                    key,
                    canvases[index].toDataURL('image/png'),
                ]),
            ),
        );
    };

    const generateTopicGroupLogos = async () => {
        const base64Logos = await Promise.all(
            report.topic_groups.map(async (i) =>
                i.topic_group_logo
                    ? getBase64ImageSource(i.topic_group_logo, '#FFFFFF')
                    : null,
            ),
        );
        setTopicGroupLogos(base64Logos);
    };

    const download = async () => {
        setAmending(true);
        setIsDownloading(true);
        setIsGenerating(true);
        try {
            validate({ throwErrors: true });
            await generateTopicGroupLogos();
            await generateCharts();
        } catch (errs) {
            setErrors(errs);
            setIsDownloading(false);
        }
        setIsGenerating(false);
    };

    useEffect(() => {
        if (!isGenerating && Object.keys(charts).length) {
            (async () => {
                const pdfReport = (
                    <Report
                        pages={pages}
                        report={reportWithLogos}
                        reportName={reportName}
                        advertiserName={advertiserName}
                        campaignName={campaignName}
                        orientation={orientation}
                        logo4D={has4DLogo ? logo4D : null}
                        brandLogo={brandLogo}
                        charts={charts}
                        isDV360Report={isDV360Report}
                    />
                );
                const pdfBlob = await pdf(pdfReport).toBlob();
                FileSaver.saveAs(pdfBlob, cleanFileName(fileName));

                setIsDownloading(false);
                onClose();
            })();
        }
    }, [isGenerating, charts]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isCustomerLogoCollapsed && errors.logo) {
            setErrors((err) => ({ ...err, logo: '' }));
        }
        if (amending) {
            setErrors(validate());
        }
    }, [amending, errors.logo, validate, isCustomerLogoCollapsed]);

    useEffect(() => {
        const set4DBase64EncodedLogo = async () => {
            setLogo4D(await getBase64ImageSource(logo));
        };
        set4DBase64EncodedLogo();
    }, [has4DLogo]);

    return (
        <Drawer onClose={onClose}>
            {isDownloading && <LoadingLayer message="Generating PDF" />}
            <Container size="small">
                <Box padding={['large', 0]}>
                    <Box padding={[0, 0, 'large']}>
                        <Text size="huge">Export PDF</Text>
                    </Box>

                    <Panel bordered>
                        <Box padding={[0, 'base']}>
                            <Box margin={['base', 0]}>
                                <Input
                                    onChange={({ target }) =>
                                        setReportName(target.value)
                                    }
                                    value={reportName}
                                    required
                                    label="Report Name"
                                    hint={
                                        <p>
                                            The report name will be displayed
                                            prominently on the front page of the
                                            report.
                                        </p>
                                    }
                                    hasError={!!errors.name}
                                    errorMessage={<p>{errors.name}</p>}
                                />
                            </Box>

                            <Box margin={['base', 0]}>
                                <Input
                                    onChange={({ target }) =>
                                        setAdvertiserName(target.value)
                                    }
                                    value={advertiserName}
                                    label="Advertiser Name"
                                    hint={
                                        <p>
                                            If an advertiser name is provided,
                                            it will be displayed prominently on
                                            the front page of the report.
                                        </p>
                                    }
                                />
                            </Box>

                            <Box margin={['base', 0]}>
                                <Input
                                    onChange={({ target }) =>
                                        setCampaignName(target.value)
                                    }
                                    value={campaignName}
                                    label="Campaign Name"
                                    hint={
                                        <p>
                                            If a campaign name is provided, it
                                            will be displayed within a small
                                            description on the front page of the
                                            report.
                                        </p>
                                    }
                                />
                            </Box>

                            <Box margin={['base', 0]}>
                                <Checkbox
                                    radio
                                    label="PDF Format"
                                    hint={<p>Choose a format to export</p>}
                                    onChange={setOrientation}
                                    options={[
                                        {
                                            label: 'Landscape',
                                            value: 'landscape',
                                            hint: (
                                                <p>
                                                    Download the landscape
                                                    format (16 x 9) and add that
                                                    into your powerpoint slides.
                                                </p>
                                            ),
                                        },
                                        {
                                            label: 'Portrait',
                                            value: 'portrait',
                                            hint: (
                                                <p>
                                                    The portrait format is A4 so
                                                    is great for printing
                                                </p>
                                            ),
                                        },
                                    ]}
                                    selectedValues={orientation}
                                />
                            </Box>

                            <PdfPageSelector
                                pages={pages}
                                onChangePages={setPages}
                                allPages={allPages}
                            />

                            <CustomImageInput
                                collapsed={isCustomerLogoCollapsed}
                                errors={errors}
                                brandLogo={brandLogo}
                                onCollapse={() => {
                                    setIsCustomerLogoCollapsed(true);
                                    setBrandLogo(null);
                                }}
                                onExpand={() =>
                                    setIsCustomerLogoCollapsed(false)
                                }
                                onLogoChange={setBrandLogo}
                                onLogoError={(logoError) =>
                                    setErrors({ ...errors, logo: logoError })
                                }
                            />

                            <Box
                                margin={['large', 0]}
                                padding="base"
                                borderRadius="round"
                                background={['gray', 'background']}
                            >
                                <Row alignItems="center">
                                    <Col>
                                        <Label
                                            label="Add 4D Logo"
                                            info={
                                                <p>
                                                    This logo will appear top
                                                    left on each page of the
                                                    report.
                                                </p>
                                            }
                                        />
                                    </Col>

                                    <Col span="auto">
                                        <Switch
                                            labels={['No', 'Yes']}
                                            isChecked={has4DLogo}
                                            onChange={() =>
                                                setHas4DLogo(
                                                    (prevState) => !prevState,
                                                )
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Box>

                            {(errors.name || errors.logo) && (
                                <Box margin={['large', 0]}>
                                    <Alert theme="danger">
                                        <p>
                                            You cannot download at the moment
                                            because there are problems that have
                                            been highlighted in the form above.
                                        </p>
                                    </Alert>
                                </Box>
                            )}

                            <Box margin={['base', 0]}>
                                <Row justifyContent="flex-end">
                                    <Col span="auto">
                                        <Button
                                            disabled={
                                                !!errors.name ||
                                                !!errors.logo ||
                                                isDownloading
                                            }
                                            onClick={download}
                                        >
                                            Download
                                        </Button>
                                    </Col>
                                </Row>
                            </Box>
                        </Box>
                    </Panel>
                </Box>
            </Container>
            {process.env.REACT_APP_CLUSTER === 'dev' && (
                <PDFViewer style={{ width: '100%', height: '100vh' }}>
                    <Report
                        pages={pages}
                        report={reportWithLogos}
                        reportName={reportName}
                        advertiserName={advertiserName}
                        campaignName={campaignName}
                        orientation={orientation}
                        logo4D={has4DLogo ? logo4D : null}
                        brandLogo={brandLogo}
                        charts={charts}
                        isDV360Report={isDV360Report}
                    />
                </PDFViewer>
            )}
            <div
                style={{
                    position: 'absolute',
                    right: '100%',
                    width: 642 * 2, // Hereinafter, the dimensions are multiplied so that the final image does not look blurry in the report
                }}
            >
                <Charts
                    orientation={orientation}
                    report={report}
                    chartRefs={chartRefs}
                />
            </div>
        </Drawer>
    );
};

export default PdfDownloadDrawer;
